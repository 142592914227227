.sort-by-container {
  .p-dropdown {
    width: 100%;

    .p-dropdown-clear-icon {
      width: 10px !important;
      height: 10px !important;
      margin-top: -5px;
      right: 30px;
    }

    .p-dropdown-label {
      padding-left: 8px !important;
      font-weight: 500;
      font-family: radikal-regular;
      width: 1.6rem !important;
      padding-top: 10px !important;
      padding-bottom: 10px !important;
    }

    .p-placeholder {
      color: var(--primary-color--blue);
    }

    .p-inputtext {
      color: var(--primary-color--blue);
    }
  }
}

.slim-scroll {
  scroll-behavior: smooth;
}

.slim-scroll::-webkit-scrollbar {
  width: 5px;
}

.slim-scroll::-webkit-scrollbar-track {
  background: #fff;
}

.slim-scroll::-webkit-scrollbar-thumb {
  background: #d9d9d9;
  border-radius: 5px;
}

.png-accordian-container {
  .p-accordion-header-link {
    background: var(--primary-color--white);
    color: var(--ISB-color);
    text-decoration: none;
    font-size: 18px;
    font-weight: 400;

    &:hover {
      background: var(--primary-color--white);
      color: var(--ISB-color);
    }

    svg {
      color: var(--primary-color--blue);
    }
  }

  .p-accordion-tab {
    border: 1px solid var(--extended-color--stroke-blue);
    border-radius: 6px;
    box-shadow: 0 1px 3px 0 #00000040;
    margin-bottom: 0;
  }

  .p-accordion .p-accordion-header .p-accordion-header-link {
    padding: 15px;
    border-color: var(--extended-color--stroke-blue);
  }

  .p-accordion-content {
    padding: 15px;
  }

  .p-icon-wrapper {
    transform: rotate(90deg);
  }

  :has(.p-accordion-tab-active) {
    .p-icon-wrapper {
      transform: rotate(-180deg);
    }
  }
}

.inc-form-container {
  .p-multiselect {
    width: 370px;
    height: 96px;
    border: 1px solid var(--outlined-button-stroke__card-last-update-text-color);
    outline: none;
    box-shadow: none;

    .p-checkbox-box {
      border-radius: 2px;
    }

    .p-multiselect-item {
      padding: 0.4rem 1rem;
    }

    .p-checkbox .p-checkbox-box {
      width: 20px;
      height: 20px;
    }
  }

  .p-multiselect-trigger {
    display: flex;
    align-items: flex-start;
    padding-top: 10px;
    width: 30px;

    svg {
      color: var(--primary-color--blue);
    }
  }

  .p-multiselect-label {
    padding-top: 7px;
    padding-left: 10px;
    font-size: 12px;
    display: inline-flex;
    overflow: auto;
    flex-wrap: wrap;

    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-track {
      background: #fff;
    }

    &::-webkit-scrollbar-thumb {
      background: #d9d9d9;
      border-radius: 5px;
    }
  }

  .p-multiselect-items {
    padding: 0;
  }

  .p-multiselect-header {
    display: none;
  }

  .p-multiselect-token {
    border: 1px solid var(--capsule-border-color);
    background: #f5f5f5;
    max-height: 30px;
    margin-bottom: 5px;

    .p-icon-wrapper {
      color: var(--primary-color--blue);
    }
  }

  .p-chip {
    border: 1px solid var(--capsule-border-color);
    background: #f5f5f5;
    max-height: 30px;
    margin: 0 5px 5px 0;

    .p-chip-text {
      font-size: 12px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 100px;
    }

    .pi-chip-remove-icon {
      color: var(--primary-color--blue);
      font-size: 12px;
    }
  }

  .ms-footer-text {
    color: var(--primary-color--blue);
    position: absolute;
    bottom: 22px;
    right: 10px;
    font-size: 14px;
    z-index: 9;
    background: #fff;
    border-radius: 4px;
  }

  .editor .NgxEditor {
    height: 165px !important;
    overflow: auto !important;
  }
}

.NgxEditor__Content {
  height: 100%;
  overflow: auto;
}

.NgxEditor__Placeholder {
  font-size: 12px;
}
