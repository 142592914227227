.button {
  padding: 10px 15px;
  font-size: 14px;
  font-weight: 500;
  font-family: radikal-medium;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 34px;
  border-radius: 4px;
  border: none;
}

.button-dark {
  background: var(--primary-color--blue);
  color: var(--primary-color--white);
}

.button-transparent {
  background: transparent;
  color: var(--primary-color--black);
}

.button-light {
  background: var(--primary-color--white);
  color: var(--primary-color--blue);
  border: 1px solid var(--outlined-button-stroke__card-last-update-text-color);
}

.button-gray {
  background: var(--disabled-bg-color);
  color: var(--disabled-text__stroke-for-outlined-color);
}

.custom-button {
  background: var(--primary-color--white) !important;
  border: 1px solid var(--outlined-button-stroke__card-last-update-text-color);
  color: var(--primary-color--blue);
}

.button-danger {
  background: var(--error-system-status-color);
  color: var(--primary-color--white);
}

button[disabled] {
  cursor: not-allowed;
}
