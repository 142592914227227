.editor {
  border: 2px solid rgb(0 0 0 / 20%);
  border-radius: 4px;

  .NgxEditor__MenuBar {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-bottom: 1px solid rgb(0 0 0 / 20%);
  }

  .NgxEditor {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border: none;
    height: 250px;
    overflow-y: scroll;
  }

  .CodeMirror {
    border: 1px solid var(--outlined-button-stroke__card-last-update-text-color);
    height: auto;
    margin-bottom: 0.7rem;

    pre {
      white-space: pre !important;
    }
  }
}

summary {
  display: block !important;
}
