// global css for multiselect autocomplete styling

.achsearch-panel {
  .ng-value {
    border-top-right-radius: 10px !important;
    border-bottom-right-radius: 10px !important;
    border-right: 1px solid !important;

    .ng-value-label {
      padding: 0 10px !important;
    }
  }
}

.search-container {
  ng-select {
    width: 100%;

    .ng-select-container {
      border: transparent;
      border-radius: 1px;
      min-height: 34px;
      height: 34px !important;
    }

    .ng-value-container {
      padding-left: 0 !important;
      flex: none !important;

      .ng-input {
        padding-left: 0 !important;
      }
    }

    .ng-clear-wrapper {
      border-top-right-radius: 10px !important;
      border-bottom-right-radius: 10px !important;
      border-top: 1px solid !important;
      border-bottom: 1px solid !important;
      border-right: 1px solid !important;
      border-color: var(
        --outlined-button-stroke__card-last-update-text-color
      ) !important;
      height: 24px !important;
      width: 34px;
      padding: 0 0 0 8px;
    }

    .ng-clear-wrapper:hover {
      background-color: var(--card-title-bg__footer-bg-color) !important;
    }

    .ng-arrow-wrapper {
      display: none !important;
    }

    ng-dropdown-panel {
      width: 604px;
      left: -52px;
      border-radius: 0 !important;
      margin-top: 1px !important;

      .ng-option {
        padding-left: 46px !important;
      }

      .ng-option:hover {
        background: var(--card-title-bg__footer-bg-color) !important;
      }

      .ng-dropdown-panel {
        background: var(--primary-color--white);
      }

      .ng-option-selected {
        background: var(--card-title-bg__footer-bg-color) !important;
      }

      .ng-option:last-child {
        border-radius: 0 !important;
      }

      .ng-option.ng-option-marked {
        background: var(--card-title-bg__footer-bg-color) !important;
      }
    }

    &[ng-reflect-clearable='true'] {
      .ng-value {
        display: flex !important;
        flex-direction: row-reverse !important;
        color: var(--primary-color--blue) !important;
        border-top: 1px solid !important;
        border-bottom: 1px solid !important;
        border-left: 1px solid !important;
        border-top-left-radius: 10px !important;
        border-bottom-left-radius: 10px !important;
        border-color: var(
          --outlined-button-stroke__card-last-update-text-color
        ) !important;
        font-size: 14px;
        height: 24px !important;

        .ng-value-label {
          padding: 0 0 0 10px;
        }
      }
    }

    &[ng-reflect-clearable='false'] {
      .ng-value {
        display: flex !important;
        flex-direction: row-reverse !important;
        color: var(--primary-color--blue) !important;
        border: 1px solid !important;
        border-radius: 10px !important;
        border-color: var(
          --outlined-button-stroke__card-last-update-text-color
        ) !important;
        font-size: 14px;
        height: 24px !important;

        .ng-value-label {
          padding: 0 10px;
          pointer-events: none !important;
        }
      }
    }

    .ng-value-icon {
      border-top-right-radius: 10px !important;
      border-bottom-right-radius: 10px !important;
      width: 24px;
      padding: 0 !important;
    }

    .ng-value-icon:hover {
      background-color: var(--card-title-bg__footer-bg-color) !important;
    }
  }

  .ng-select .ng-select-container {
    max-height: 200px !important;
  }

  .ng-select.ng-select-multiple .ng-select-container {
    display: contents !important;
  }

  .ng-select.ng-select-focused:not(.ng-select-opened) > .ng-select-container {
    box-shadow: none !important;
  }

  .ng-select.ng-select-multiple
    .ng-select-container
    .ng-value-container
    .ng-value {
    background-color: var(--primary-color--white);
    border-right: 1px solid !important;
    border-top-right-radius: 10px !important;
    border-bottom-right-radius: 10px !important;
    border-color: var(
      --outlined-button-stroke__card-last-update-text-color
    ) !important;
    font-size: 14px;
    height: 24px !important;

    .ng-value-label {
      padding: 0 10px;
    }
  }

  .ng-select.ng-select-multiple .ng-select-container .ng-value-container {
    max-width: 600px;
  }

  .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-disabled {
    color: var(--primary-color--black) !important;
  }
}
