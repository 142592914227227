/* You can add global styles to this file, and also import other style files */
@import '~@ng-select/ng-select/themes/default.theme.css';
@import './assets/fonts/fonts';
@import '~primeicons/primeicons.css';

body {
  font-family: radikal-regular !important;
  font-size: 13px;
  background-color: var(--frame-background-color) !important;
  font-weight: 400 !important;

  .p-tooltip-text {
    font-family: radikal-regular !important;
  }
}

.form-control {
  border-radius: 0;
  border: none !important;

  // padding: 0.375rem 0.76rem !important;
}

.form-control:focus {
  box-shadow: none !important;
  border: none !important;
}

// checkbox css starts
.checkbox-container {
  display: inline-block;
  position: relative;
  padding-left: 25px;
  margin-bottom: 20px;
  cursor: pointer;
  font-size: 13px;
  user-select: none;
}

.checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  width: 18px;
  height: 18px;
  border: 2px solid var(--outlined-button-stroke__card-last-update-text-color);
}

.checkmark::after {
  content: '';
  position: absolute;
  display: none;
}

/* Style the checkmark/indicator */
.checkbox-container .checkmark::after {
  left: 5px;
  top: 1px;
  width: 5px;
  height: 10px;
  border: solid var(--primary-color--white);
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.checkbox-container input:checked ~ .checkmark::after {
  display: block;
}

.checkbox-container input:checked ~ .checkmark {
  border: 2px solid var(--primary-color--blue);
  background-color: var(--primary-color--blue);
}

.checkbox-container input[disabled] ~ .checkmark {
  cursor: no-drop;
  background: var(--disabled-bg-color);
  border-color: var(--disabled-text__stroke-for-outlined-color);
}

.checkbox-container input[disabled] ~ .checkmark::after {
  border-color: var(--primary-color--white);
}

// chsckbox css ends

// radio button css starts
.radiobtn-container {
  display: block;
  position: relative;
  cursor: pointer;
  font-size: 14px;
  user-select: none;
  margin-right: 20px;
}

.radiobtn-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.radiobtn-container:has(input:disabled) {
  cursor: not-allowed;
  pointer-events: none;
}

.radiobtncheckmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid
    var(--header-sub-titleHeader__last-updated-card-status-color);
}

.radiobtncheckmark::after {
  content: '';
  position: absolute;
  display: none;
}

.radiobtn-container .radiobtncheckmark::after {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: var(--primary-color--blue);
}

.radiobtn-container input:checked ~ .radiobtncheckmark::after {
  display: block;
}

// radio button css ends

// form input controls
.n-input-group {
  margin-bottom: 15px;
  position: relative;

  label {
    font-weight: 700;
    font-size: 14px;
  }

  .n-form-control {
    height: 34px;
    width: 100%;
    border: 1px solid var(--outlined-button-stroke__card-last-update-text-color);
    padding: 5px 10px;
    font-weight: 300;
    font-size: 12px;
    border-radius: 4px;
  }

  .n-form-control:focus {
    outline: none;
  }

  // readonly input background globaly
  .readonlyBg {
    border-color: var(--disabled-text__stroke-for-outlined-color) !important;
    background: var(--disabled-bg-color);
    cursor: no-drop;
  }

  .ncontrol::placeholder {
    color: var(--outlined-button-stroke__card-last-update-text-color);
    font-weight: 300;
    font-size: 12px;
  }

  .n-form-control::placeholder {
    /* Most modern browsers support this now. */
    color: var(--outlined-button-stroke__card-last-update-text-color);
    font-weight: 300;
    font-size: 12px;
  }

  .error-container {
    position: absolute;

    .error {
      color: var(--error-system-status-color);
    }
  }
}

/* registration summary */
.summary-content p {
  margin-bottom: 0.2rem !important;
}

.modalsubhead {
  font-size: 12px;
}

.disabled-placeholder {
  background: #aaa;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.5;
  cursor: no-drop;
}

button:focus {
  outline: none !important;
}

.filter-box-container {
  position: relative;

  .filter-input-box {
    width: 100%;
    border-top: none;
    border-right: none;
    border: none;
    border-left: none;
    font-size: 16px;
    color: var(--primary-color--black);
  }

  .filter-input-box:focus {
    outline: none;
  }

  .filterbox::placeholder {
    color: var(--primary-color--black);
  }

  .filter-input-box::placeholder {
    /* Most modern browsers support this now. */
    color: var(--primary-color--black);
  }

  img {
    position: absolute;
    top: 2px;
    right: 2px;
  }
}

.no-result {
  font-size: 16px;
  height: 100px;
}

.alert-warning-light {
  background: var(--info-bg-color);
}

.accordia-seperator {
  height: 45%;
  width: 1px;
  background: var(--separators__pipelines-color);
  margin: 0 15px;
}

.dndDraggableDisabled {
  cursor: no-drop !important;
}

.filter-box-container button-component button {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.accordion-body {
  .dndDropzoneDisabled {
    .placeholder-container {
      cursor: no-drop !important;
      border-color: var(--disabled-text__stroke-for-outlined-color);

      .placeholder-txt {
        color: var(--disabled-text__stroke-for-outlined-color);
        border-color: var(--disabled-text__stroke-for-outlined-color);
      }

      button {
        color: var(--disabled-text__stroke-for-outlined-color);
        border-color: var(--disabled-text__stroke-for-outlined-color);
        cursor: no-drop !important;
      }
    }
  }
}

.contact-msg {
  margin: 0 0 15px;
  background: var(--info-bg-color);
  box-shadow: 0 1.5px 5px 0 rgb(0 0 0 / 8%);
  border-radius: 4px;
  padding: 15px;

  p {
    margin: 0;
  }

  img {
    margin-right: 15px;
    margin-top: 4px;
  }
}

.alert-icon img {
  margin-right: 15px;
  margin-top: 5px;
}

.page-title-container {
  margin-bottom: 15px;
  margin-right: -27px !important;
  margin-left: -20px !important;

  .page-title {
    min-height: 58px;
    background-color: var(--primary-color--white);
    padding-left: 15px;
    padding-right: 15px;

    .page-title-text {
      color: var(--primary-nav__page-title__card-title-color);
      font-size: 30px;
      font-style: normal;
      line-height: 20px;
    }
  }

  .page-title-details {
    background-color: var(--primary-color--white);
    padding-left: 15px;
    padding-right: 15px;
  }
}

.alert-container {
  position: absolute;
  width: 100%;
  margin-top: -15px;
  margin-right: -27px !important;
  margin-left: -20px !important;
  z-index: 1000;
}

.btnImgRight img {
  margin-left: 10px;
}

.btnImgLeft img {
  margin-right: 10px;
}

.grid-table-spacer {
  margin-bottom: 30px;
}

p-dropdown.p-element {
  width: 100%;
}

.drop-down-filter.p-dropdown.p-component {
  height: 100%;
  align-items: center;
}

.drop-down-icon::before {
  content: url('/assets/icons/arrow_drop_down.svg');
  align-items: center;
}

.export-icon::before {
  content: url('/assets/icons/share_windows.svg');
  align-items: center;
}

.p-inputtext {
  padding: 5px !important;
  font-size: 14px !important;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
  padding: 0.4rem 1.25rem !important;
}

.p-dropdown-panel
  .p-dropdown-items
  .p-dropdown-item:not(.p-highlight, .p-disabled):hover {
  background: var(--drodown-hover-color) !important;
}

.p-dropdown .p-dropdown-trigger {
  width: 2.25rem !important;
}

.calendar-icon::before {
  content: url('/assets/icons/calendar_month.svg');
  padding: 5px 0 0 10px;
}

button.p-element.p-button-text {
  box-shadow: none !important;
}

.p-datepicker .p-datepicker-buttonbar {
  height: 30px !important;
}

.p-datepicker .p-datepicker-header {
  height: 30px !important;
  padding: 0.2rem !important;
}

.p-calendar {
  height: 35px !important;
  width: 300px !important;

  .p-datepicker-year {
    // There is a bug in PrimeNG v15.X where the years don't
    // display on the "Year" selector. This removes the ability to
    // see that broken feature.
    pointer-events: none;
  }

  .p-datepicker-trigger {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
}

.p-button.p-button-icon-only {
  width: 2rem !important;
}

.p-datepicker table td {
  padding: 0.2rem !important;
}

.p-datepicker table td > span {
  width: 1.4rem !important;
  height: 1.4rem !important;
}

.p-datepicker table {
  font-size: 14px !important;
  margin: -0.143rem 0 !important;
}

.p-dropdown {
  border-radius: 4px !important;
  border: 1px solid var(--outlined-button-stroke-card-last-update-text, #757575) !important;
  background: #fff !important;
}

.p-dropdown:not(.p-disabled).p-focus,
.p-inputtext.p-component,
.p-inputtext.p-component:hover,
.p-button:focus,
.p-dropdown,
.p-dropdown:not(.p-disabled):hover {
  box-shadow: none !important;
  border: 1px solid var(--outlined-button-stroke-card-last-update-text) !important;
}

.grid-time-wrap {
  font-size: 12px;
  color: var(--gray-grid-color);
}

.p-splitbutton-defaultbutton.p-button.p-component {
  background: var(--primary-color--blue) !important;
  border: 1px solid var(--primary-color--blue) !important;
  padding: 0 !important;
}

.p-splitbutton-menubutton.p-button-icon-only.p-button.p-component {
  background: var(--primary-color--blue) !important;
  border: 1px solid var(--primary-color--blue) !important;
  padding: 0 0 0 10px !important;
}

.p-button-icon-left.export-icon {
  margin-top: 2px !important;
}

.p-tieredmenu {
  width: 134px !important;
}

.p-column-filter-overlay-menu .p-column-filter-buttonbar {
  padding: 0 1rem 1rem !important;
}

.p-column-filter-buttonbar .p-button {
  border-radius: 4px;
}

.p-column-filter-buttonbar .p-button .p-button-label {
  font-family: radikal-regular !important;
}

.p-column-filter-overlay {
  min-width: 300px !important;
}

/* stylelint-disable selector-type-no-unknown */
.p-dropdown-trigger chevrondownicon.p-element.p-icon-wrapper {
  color: var(--primary-color--blue) !important;
}
/* stylelint-enable selector-type-no-unknown */

.p-dropdown-trigger svg.p-dropdown-trigger-icon.p-icon {
  width: 12px !important;
  height: 12px !important;
}

.tps-upload-history .p-splitbutton {
  margin-left: 0 !important;
}

.tps-upload-history .p-button .p-button-label {
  font-weight: 400 !important;
  font-size: 14px !important;
}

.contribution-main-container .p-datepicker-trigger {
  background: transparent !important;
  border-left: none;
  border-color: var(
    --outlined-button-stroke__card-last-update-text-color
  ) !important;

  .p-icon-wrapper {
    color: var(--primary-color--blue) !important;
  }
}

.btn80 {
  width: 80px;
}

.contphpos {
  width: 60% !important;
  top: 57px;
  border-radius: 4px !important;
}

.card-badge-n-action alert-message .div-alert-message {
  padding: 10px !important;
}

.prl contact-detail-card:nth-child(1) .contact-card-container {
  margin-top: 10px !important;
}

.itwidth p-calendar {
  width: 200px;
}

.align-top {
  vertical-align: top;
}

span.p-button-label {
  font-weight: 100;
  font-size: 14px;
}

.p-icon {
  width: 14px !important;
  height: 14px !important;
}

.log-entry-value {
  margin-top: 5px;
}

.base-container {
  background-color: var(--primary-color--white);
  border-radius: 4px;
  padding: 15px;
  margin-bottom: 15px;
}

.base-container-title {
  font-family: radikal-medium;
  color: var(--primary-nav__page-title__card-title-color);
  font-size: 24px;
}

.no-results {
  height: 70px;
}

.ml-5 {
  margin-left: 5px;
}

.ml-15 {
  margin-left: 15px;
}

/* Global tooltip style */
.p-tooltip .p-tooltip-text {
  border: 1px solid var(--outlined-button-stroke-card-last-update-text, #757575);
  background-color: #fff !important;
  color: var(--primary-text-color-non-clickable-icon, #3d3c3c) !important;
  filter: drop-shadow(0 0 5px rgb(0 0 0 / 25%));
  font-size: 11px;
  font-weight: 400;
  font-family: Radikal;
  line-height: 18px;
}

.error-icon {
  position: absolute;
  right: 10px;
  bottom: 5px;
}

.login-progress {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  .progress-box {
    width: 400px;
    height: 300px;
    border-radius: 10px;
    background: var(--primary-color--white);
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.ng2-pdf-viewer-container {
  overflow-x: hidden !important;
}

.pa-main-container .msg-container-success {
  margin-top: -15px;
}

.required {
  color: var(--error-system-status-color);
}

.tdn {
  text-decoration: none;
}

.uid-container {
  font-size: 16px;
  color: var(--gray-grid-color);
  margin-top: 15px;
  padding-left: 15px;
}

input[type='text']:read-only {
  background: var(--disabled-bg-color) !important;
  cursor: not-allowed !important;
  outline: none !important;
  color: var(--primary-text-color) !important;
}

input[readonly],
textarea[readonly],
select[disabled] {
  background: var(--disabled-bg-color);
  color: var(--primary-text-color);
  cursor: not-allowed;
}

.ng-select.ng-select-disabled > .ng-select-container {
  background-color: var(--disabled-bg-color) !important;
  border-color: var(--disabled-text__stroke-for-outlined-color) !important;
  cursor: not-allowed !important;
}

.ng-select.ng-select-disabled .ng-arrow-wrapper {
  display: none;
}

.ng-select.ng-select-multiple.ng-select-disabled
  > .ng-select-container
  .ng-value-container
  .ng-value {
  background-color: var(--disabled-bg-color) !important;
  border-color: var(--disabled-bg-color) !important;
  color: var(--primary-text-color) !important;
  cursor: not-allowed !important;
  font-size: 14px;
}

.p-tieredmenu-overlay {
  ul {
    padding: 0;

    a {
      text-decoration: none;
    }
  }
}

.custum-field-modal-container .p-calendar input {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

p-dropdown .p-disabled {
  background: var(--disabled-bg-color) !important;
}

p-sorticon {
  .sortamountupalticon {
    padding: 3px 5px;
    background-color: #3b69d1;
    color: #ffff;
    border-radius: 20px;
  }

  .sortamountdownicon {
    padding: 3px 5px;
    background-color: #3b69d1;
    color: #ffff;
    border-radius: 20px;
  }
}

.delete-se-container {
  position: fixed;
  z-index: 99999;
  top: 139px;
  left: 47px;
  margin-bottom: 10px;
  width: 97%;
}

@media only screen and (width >= 100px) and (width <= 1330px) {
  .delete-se-container {
    .msg-container-success {
      width: 122.5% !important;
    }
  }
}

.inbox-table-wrap {
  margin-top: 15px !important;
  margin-left: 12px !important;
  width: 98% !important;
}
