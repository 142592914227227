@font-face {
    font-family: radikal-black;
    src: url("./radikal/Radikal-Black.otf") format("opentype");
}

@font-face {
    font-family: radikal-ultra-thin-italic;
    src: url("./radikal/Radikal-UltraThinItalic.otf") format("opentype");
}

@font-face {
    font-family: radikal-black-italic;
    src: url("./radikal/Radikal-BlackItalic.otf") format("opentype");
}

@font-face {
    font-family: radikal-bold;
    src: url("./radikal/Radikal-Bold.otf") format("opentype");
}

@font-face {
    font-family: radikal-bold-italic;
    src: url("./radikal/Radikal-BoldItalic.otf") format("opentype");
}

@font-face {
    font-family: radikal-light;
    src: url("./radikal/Radikal-Light.otf") format("opentype");
}

@font-face {
    font-family: radikal-light-italic;
    src: url("./radikal/Radikal-LightItalic.otf") format("opentype");
}

@font-face {
    font-family: radikal-medium;
    src: url("./radikal/Radikal-Medium.otf") format("opentype");
}

@font-face {
    font-family: radikal-medium-italic;
    src: url("./radikal/Radikal-MediumItalic.otf") format("opentype");
}

@font-face {
    font-family: radikal-regular;
    src: url("./radikal/Radikal-Regular.otf") format("opentype");
}

@font-face {
    font-family: radikal-regular-italic;
    src: url("./radikal/Radikal-RegularItalic.otf") format("opentype");
}

@font-face {
    font-family: radikal-thin;
    src: url("./radikal/Radikal-Thin.otf") format("opentype");
}

@font-face {
    font-family: radikal-thin-italic;
    src: url("./radikal/Radikal-ThinItalic.otf") format("opentype");
}

@font-face {
    font-family: radikal-ultra-thin;
    src: url("./radikal/Radikal-UltraThin.otf") format("opentype");
}