// Global color scheme
:root {
  // Primary Colors
  --primary-color--green: #5ac139;
  --primary-color--blue: #2f6cf6;
  --primary-color--light-green: #7dfc54;
  --primary-color--light-blue: #6796ff;
  --primary-color--black: #000;
  --primary-color--orange: #df7534;
  --primary-color--orange-lite: #fffdf4;
  --primary-color--grey: #a3a3a3;
  --primary-color--white: #fff;
  --success-color-green: #4caf50;
  --success-color-bg: #f7fff7;
  --lemon: #fffdf4;
  --supportHover: #ebf1ff;
  --ru-color: #14203b;
  --otherFontColor: #3d3c3c;
  --pa-input-label: #3d3c3c;
  --subHeadColor: ##3d3c3c;

  // Extended color palette
  --card-title-bg__footer-bg-color: #ebf1ff;
  --extended-color--stroke-blue: #9ebcff;
  --primary-nav__page-title__card-title-color: #1a2845;
  --primary-nav-color--hover: #37435e;
  --filled-button-color--hover-bg: #3b69d1;
  --filled-button-color--pressed-bg: #598bfc;
  --primary-info-color: #aaafb3;

  // Hyperlink Colors
  --hyperlink-color: #4600eb;
  --hyperlink-color--clicked: #4a148c;

  // Typography
  --primary-text-color: #3d3c3c;

  // Tertiary Colors
  // Tertiary Reds
  --error-bg-color: #fff8f8;
  --error-system-status-color: #b71c1c;
  --error-bg-color-compliance: #fcebe8;
  --error-icon-color: #cd5c5c;
  --badge-error-color: #c54500;
  --danger-lite: #b71c1c;
  --danger-lite-bg: #fff0f0;

  // Tertiary Blues
  --info-bg-color: #f7f9ff;
  --info-icon-color: #1444b1;
  --drodown-hover-color: #eff6ff;

  // Tertiary Greens
  --active-bg-color: #c8e6c9;
  --active-text-color: #1b5e20;
  --success-icon-color: #4caf50;
  --success-text-color: #257929;
  --success-bg-color: #f7fff7;
  --success-border-color: #f0f8ff;

  // Tertiary Yellows
  --warning-ststus-bg-color: #fffdf4;
  --warning-icon-color: #ff9800;

  // Grey Scale
  --footer-bg__outlined-icon-bg__footer-bg-color: #fafafa;
  --outlined-icons-bg-focussed-and-pressed-color: #f5f5f5;
  --frame-background-color: #eee;
  --disabled-bg-color: #e0e0e0;
  --separators__pipelines-color: #bdbdbd;
  --disabled-text__stroke-for-outlined-color: #9e9e9e;
  --outlined-button-stroke__card-last-update-text-color: #757575;
  --inactive-stroke-and-text-color: #424242;
  --header-sub-titleHeader__last-updated-card-status-color: #616161;
  --file-upload-text-color: #b5b2b2;
  --file-upload-background: #e6e6e6;
  --outlined-button-stroke-card-last-update-text: #757575;
  --gray-grid-color: #7b7b7b;
  --modal-footer-color: #f1f0f0;

  // Modal
  --modal-opacity-color: rgb(0 0 0 / 50%);

  // grid cnew olor
  --table-row-hover-color: #f1ffec;

  // warning color
  --warning-bg-color: #fffaf3;

  // shadow color
  --box-shadow-color: #0009;

  // capsule border color
  --capsule-border-color: #898989;
  --ISB-color: #3d3c3c;
}
