p-columnfilterformelement button {
  margin-bottom: 0 !important;
}

p-table {
  thead {
    th {
      height: 64px !important;
    }
  }

  .p-column-filter {
    button {
      width: 24px !important;
      height: 24px !important;
      margin-left: 0;
    }
  }

  .p-icon-wrapper {
    color: var(--primary-color--white);
  }
}

.p-datatable-wrapper {
  border-radius: 4px 4px 0 0;
}

.p-datatable .p-datatable-thead > tr {
  border: 1px solid var(--primary-nav__page-title__card-title-color) !important;
}

p-sorticon .p-icon-wrapper svg g {
  color: var(--primary-color--white);
}

.p-button.p-button-text.p-button-plain:enabled:hover {
  background: var(--filled-button-color--hover-bg) !important;
  color: var(--primary-color--white) !important;
  box-shadow: none !important;
  outline: none !important;
  border: none !important;
}

.expandRow {
  background: var(--filled-button-color--hover-bg) !important;
  color: var(--primary-color--white) !important;
  font-weight: 400;
}

.expandRow td {
  color: var(--primary-color--white) !important;
}

.p-datatable-tbody td button {
  padding: 0;
}

.expandedbg {
  background: var(--card-title-bg__footer-bg-color) !important;

  .expended-row-container {
    position: relative;
    width: 100%;
    display: flex;
    flex-wrap: wrap;

    .expended-col {
      position: relative;
      padding: 5px 10px 5px 18px;

      .expanded-col-heading {
        font-size: 14px;
        color: var(--primary-text-color);
        font-weight: 700;
        margin-bottom: 5px;
      }

      .expanded-col-data {
        font-size: 14px;
        color: var(--primary-text-color);
      }
    }
  }
}

.expandedLinkColor {
  color: var(--hyperlink-color);
}

.notExpandedLinkColor {
  color: var(--primary-color--white);
}

.notExpandedLinkColor:hover {
  color: var(--primary-color--white);
}

// table css
.p-datatable .p-datatable-thead > tr > th {
  background: var(--primary-nav__page-title__card-title-color) !important;
  padding: 0.5rem !important;
  height: 46px;
  font-size: 14px;
  position: sticky;
  top: 0;
  color: var(--primary-color--white) !important;
  border: none !important;
}

.p-component {
  font-family: radikal-regular !important;
  font-size: 14px;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.235px;
}

.accordianheading {
  font-size: 24px !important;
  margin-bottom: 0;
}

p-table.p-element {
  word-break: normal;
  overflow-wrap: anywhere;
}

.p-column-filter-menu-button:hover {
  background: #37435e !important;
  border-radius: 20px;
}

@media screen and (width <= 64em) {
  .p-datatable .p-datatable-thead > tr > th {
    top: 0;
  }
}

.p-column-filter-menu-button.p-column-filter-menu-button-open {
  background: transparent !important;
}

.p-column-filter-menu-button:focus {
  box-shadow: none !important;
}

p-columnfilterformelement p-dropdown .p-inputtext {
  padding: 5px 10px 10px !important;
  font-size: 14px !important;
}

.p-column-filter-matchmode-dropdown {
  height: 34px !important;
  outline: none !important;
  box-shadow: none !important;

  .p-inputtext {
    padding: 6px 10px 0 !important;
    font-size: 14px !important;
  }
}

.p-column-filter-constraint {
  padding: 15px !important;
}

.p-column-filter-buttonbar {
  padding: 0 !important;

  .p-button-label {
    font-family: Roboto !important;
  }

  .p-button-outlined {
    color: #2f6cf6 !important;
  }
}

.p-column-filter-overlay {
  border-radius: 4px !important;
}

p-columnfilterformelement input[type='text'] {
  padding: 8px 10px 10px !important;
  font-size: 14px !important;
}

p-columnfilterformelement p-dropdown .p-dropdown {
  height: 34px !important;
  border-radius: 4px !important;
  box-shadow: none !important;
}

p-columnfilterformelement
  p-dropdown
  .p-dropdown-panel
  .p-dropdown-items
  .p-dropdown-item {
  padding: 5px 10px 10px !important;
  font-size: 14px !important;
}

p-columnfilterformelement p-dropdown .p-dropdown-panel .p-dropdown-items {
  padding: 0 !important;
}

p-dropdown ul {
  padding: 0 !important;
}

p-dropdownitem li {
  color: var(--primary-text-color-non-clickable-icon, #3d3c3c) !important;
  padding: 5px 10px 10px !important;
  font-size: 14px !important;
}

.p-datatable-table {
  border: 1px solid #757575;
}

.p-datatable .p-datatable-tbody > tr {
  min-height: 58px !important;
}

.p-datatable .p-sortable-column .p-sortable-column-icon {
  margin-left: 0 !important;
}

.p-datatable .p-sortable-column {
  background: var(--primary-nav__page-title__card-title-color) !important;
}

.p-datatable .p-datatable-tbody > tr > td {
  height: 58px !important;
  padding: 0.24rem 1rem !important;
  font-size: 14px;
  color: var(--primary-text-color);
  border: none !important;
}

.p-datatable {
  .p-datatable-tbody {
    > tr.deActivatedRow {
      border: 1px solid var(--outlined-button-stroke-card-last-update-text) !important;
      background: var(--disabled-bg-color) !important;

      > td {
        border-top: 1px solid
          var(--outlined-button-stroke-card-last-update-text) !important;
        border-bottom: 1px solid
          var(--outlined-button-stroke-card-last-update-text) !important;
        color: var(
          --header-sub-titleHeader__last-updated-card-status-color
        ) !important;
      }
    }
  }
}

.p-datatable .p-sortable-column:focus {
  box-shadow: none !important;
  outline: 0 none !important;
}

.p-datatable > .p-datatable-wrapper {
  overflow: unset !important;
}

select {
  option {
    width: auto;
    border: none transparent;
  }

  font-size: 14px;
  color: var(--primary-text-color-non-clickable-icon);
}

.noRecordsDiv {
  height: 70px;
}

p-sorticon {
  width: 24px;
  height: 24px;
  border-radius: 20px;
  text-align: center;
}

p-sorticon:hover {
  background: #37435e;
  border-radius: 20px;
}

p-columnfilter {
  width: 24px;
  height: 24px;
  margin-left: 3px;
  border-radius: 20px;
  text-align: center;
  margin-top: -3px;
}

p-columnfilter:hover {
  background: #37435e;
  width: 22px;
  height: 22px;
  margin-left: 3px;
  border-radius: 20px;
  margin-top: -3px;
}

.p-column-filter-menu-button.p-column-filter-menu-button-active,
.p-column-filter-menu-button.p-column-filter-menu-button-active:hover {
  background-color: #3b69d1;
  color: #ffff;
  border-radius: 20px;
  margin-top: -1px;
}

.calender-active {
  background-color: #3b69d1 !important;
  color: #ffff !important;
  border-radius: 20px !important;
  margin-top: -3px;
}

.align-items-start {
  display: flex !important;
}
