.pr {
  position: relative;
}

.pd0 {
  padding: 0 !important;
}

.text-center {
  text-align: center;
}

.mr0 {
  margin: 0 !important;
}

.text-right {
  text-align: right;
}

.pdr0 {
  padding-right: 0 !important;
}

.text-danger {
  color: var(--error-system-status-color);
}

.c-p {
  cursor: pointer;
}

.fw-300 {
  font-family: radikal-light;
  font-weight: 300;
}

.fw-400 {
  font-family: radikal-regular;
  font-weight: 400;
}

.fw-500 {
  font-family: radikal-medium;
  font-weight: 500;
}

.fw-600 {
  font-family: radikal-regular;
  font-weight: 600;
}

.fw-700 {
  font-family: radikal-bold;
  font-weight: 700;
}

.w100 {
  width: 100%;
}

.h100 {
  height: 100%;
}

.success {
  background-color: var(--success-bg-color) !important;
}

.info {
  background-color: var(--info-bg-color);
}

.warning {
  background-color: var(--warning-ststus-bg-color);
}

.error {
  background-color: var(--error-bg-color);
}

.ml-10 {
  margin-left: 10px;
}

.mb0 {
  margin-bottom: 0;
}

.selectBox {
  height: 34px;
  background: url('/assets/icons/expand-collapse-arrow.svg') no-repeat right
    var(--primary-color--white);
  border-radius: 4px;
  background-position-x: calc(100% - 10px);
  font-size: 14px;
  text-align-last: left;
  padding-left: 5px;
  appearance: none;
}

.selectBox:focus {
  outline: none;
}

.oh {
  overflow: hidden;
}

.oxh {
  overflow-x: hidden;
}

.oyh {
  overflow-y: hidden;
}

.custum-field-modal-container {
  p-inputnumber {
    width: 100%;
  }

  .p-inputnumber {
    width: 100%;
  }

  .p-inputtext {
    height: 34px;
    width: 100%;
    border: 1px solid var(--outlined-button-stroke__card-last-update-text-color) !important;
    padding: 5px 10px !important;
    font-weight: 300;
    font-size: 12px;
    border-radius: 4px;
  }

  p-calendar {
    .p-datepicker-trigger {
      background: var(--primary-color--white);
      color: var(--primary-color--blue);
      border-color: var(--outlined-button-stroke__card-last-update-text-color);
      border-left: none;
      height: 34px;
    }

    .p-datepicker-trigger:hover {
      background: var(--primary-color--white);
      color: var(--primary-color--blue);
      border-color: var(--outlined-button-stroke__card-last-update-text-color);
    }
  }
}

// UTILS CSS CLASSES
.rmt1 {
  margin-top: 1px;
}

.rmt2 {
  margin-top: 2px;
}

.rmt3 {
  margin-top: 3px;
}

.rmt4 {
  margin-top: 4px;
}

.rmt5 {
  margin-top: 5px;
}

.rmt6 {
  margin-top: 6px;
}

.rmt7 {
  margin-top: 7px;
}

.rmt8 {
  margin-top: 8px;
}

.rmt9 {
  margin-top: 9px;
}

.rmt10 {
  margin-top: 10px;
}

.rmt11 {
  margin-top: 11px;
}

.rmt12 {
  margin-top: 12px;
}

.rmt13 {
  margin-top: 13px;
}

.rmt14 {
  margin-top: 14px;
}

.rmt15 {
  margin-top: 15px;
}

.rmt16 {
  margin-top: 16px;
}

.rmt17 {
  margin-top: 17px;
}

.rmt18 {
  margin-top: 18px;
}

.rmt19 {
  margin-top: 19px;
}

.rmt20 {
  margin-top: 20px;
}

.rmb1 {
  margin-bottom: 1px;
}

.rmb2 {
  margin-bottom: 2px;
}

.rmb3 {
  margin-bottom: 3px;
}

.rmb4 {
  margin-bottom: 4px;
}

.rmb5 {
  margin-bottom: 5px;
}

.rmb6 {
  margin-bottom: 6px;
}

.rmb7 {
  margin-bottom: 7px;
}

.rmb8 {
  margin-bottom: 8px;
}

.rmb9 {
  margin-bottom: 9px;
}

.rmb10 {
  margin-bottom: 10px;
}

.rmb11 {
  margin-bottom: 11px;
}

.rmb12 {
  margin-bottom: 12px;
}

.rmb13 {
  margin-bottom: 13px;
}

.rmb14 {
  margin-bottom: 14px;
}

.rmb15 {
  margin-bottom: 15px;
}

.rmb16 {
  margin-bottom: 16px;
}

.rmb17 {
  margin-bottom: 17px;
}

.rmb18 {
  margin-bottom: 18px;
}

.rmb19 {
  margin-bottom: 19px;
}

.rmb20 {
  margin-bottom: 20px;
}

.rml1 {
  margin-left: 1px;
}

.rml2 {
  margin-left: 2px;
}

.rml3 {
  margin-left: 3px;
}

.rml4 {
  margin-left: 4px;
}

.rml5 {
  margin-left: 5px;
}

.rml6 {
  margin-left: 6px;
}

.rml7 {
  margin-left: 7px;
}

.rml8 {
  margin-left: 8px;
}

.rml9 {
  margin-left: 9px;
}

.rml10 {
  margin-left: 10px;
}

.rml11 {
  margin-left: 11px;
}

.rml12 {
  margin-left: 12px;
}

.rml13 {
  margin-left: 13px;
}

.rml14 {
  margin-left: 14px;
}

.rml15 {
  margin-left: 15px;
}

.rml16 {
  margin-left: 16px;
}

.rml17 {
  margin-left: 17px;
}

.rml18 {
  margin-left: 18px;
}

.rml19 {
  margin-left: 19px;
}

.rml20 {
  margin-left: 20px;
}

.rmr1 {
  margin-right: 1px;
}

.rmr2 {
  margin-right: 2px;
}

.rmr3 {
  margin-right: 3px;
}

.rmr4 {
  margin-right: 4px;
}

.rmr5 {
  margin-right: 5px;
}

.rmr6 {
  margin-right: 6px;
}

.rmr7 {
  margin-right: 7px;
}

.rmr8 {
  margin-right: 8px;
}

.rmr9 {
  margin-right: 9px;
}

.rmr10 {
  margin-right: 10px;
}

.rmr11 {
  margin-right: 11px;
}

.rmr12 {
  margin-right: 12px;
}

.rmr13 {
  margin-right: 13px;
}

.rmr14 {
  margin-right: 14px;
}

.rmr15 {
  margin-right: 15px;
}

.rmr16 {
  margin-right: 16px;
}

.rmr17 {
  margin-right: 17px;
}

.rmr18 {
  margin-right: 18px;
}

.rmr19 {
  margin-right: 19px;
}

.rmr20 {
  margin-right: 20px;
}
